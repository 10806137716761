.slider-btn{
    position: relative;
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 30px;
}

.slider-arrow{
    border: 1px solid #FEC012;
    color: #FEC012;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}