.gallery-section {
    background: url("../../assets/images/gallery/Black-bg\ 3\ \(1\).png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 0;
    position: relative;
}


.gallery-leaf {
    position: absolute;
    top: -40px;
    right: 0;
    z-index: -1;
}

.tabList {
    display: flex;
    text-align: center;
    justify-content: center;
    padding-left: 0;
    gap: 30px;
}

.tab {
    padding: 10px;
    list-style: none;
    cursor: pointer;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #fff;
    font-family: "DM Serif Text", serif;
}

.tab.active {
    border-bottom: 2px solid #FEC012;
    color: #fff;
}

.tab:focus-visible {
    outline: unset !important;
}

.tab-content {
    margin-top: 60px;
}

.gallery-image-container {
    background: url("../../assets/images/gallery/Vector\ 14.png");
    background-repeat: no-repeat;
    background-position: top left;
}

.gallery-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 60px;
}

.gallery-image {
    padding: 20px;
}

.gallery-image-container:nth-child(even) {
    margin-top: 60px;
}








@media (max-width:576px) {
    .tab {
        font-size: 18px;
    }

    .tabList {
        justify-content: unset;
    }

    .gallery-image {
        padding: 10px;
    }
}



@media (max-width:767px) {
    .gallery-section {
        padding: 40px 0;
    }

    .tab-content {
        margin-top: 40px;
    }

    .tabList {
        overflow-y: scroll;
        gap: 20px;
    }

    .gallery-images {
        gap: 20px;
    }

    .gallery-image {
        width: 100%;
    }

    .gallery-image img {
        width: 100%;
    }

    .gallery-image-container:nth-child(even) {
        margin-top: 0;
    }

}

@media (min-width:577px) and (max-width:767px) {
    .tab {
        font-size: 20px;
    }
}

@media (min-width:768px) and (max-width:1199px) {
    .gallery-section {
        padding: 60px 0;
    }

    .gallery-images {
        gap: 20px;
    }

    .gallery-image-container {
        width: 47%;
    }

    .gallery-image img {
        width: 100%;
    }

    .gallery-image-container:nth-child(even) {
        margin-top: 40px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .tab {
        font-size: 24px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .tab {
        font-size: 27px;
    }
}

@media (min-width:1200px) and (max-width:1400px) {
    .tab {
        font-size: 30px;
    }
}