.reservation-section {
    position: relative;
    padding: 80px 0;
}

.green-leaf {
    position: absolute;
    top: -70px;
    left: 0;
    z-index: -1;
}

.pizza {
    position: absolute;
    right: 0;
    bottom: 0px;
    z-index: -1;
}

.ice-cream {
    position: absolute;
    bottom: -370px;
    left: 0;
    z-index: -1;
}


.reservation-section-header {
    text-align: center;
}


.reservation-section-content {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}

.reservation-form {
    width: 60%;
    padding: 40px;
    position: relative;
}


.reservation-location {
    width: 38%;
    padding: 40px;
}

.form-top-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.form-bottom-box {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.form-container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
}

.form-group {
    flex: 0 0 auto;
    width: 50%;
    max-width: 100%;
    padding-right: calc(1.5rem * .5);
    padding-left: calc(1.5rem * .5);
    margin-top: 0;
    margin-bottom: 1.5rem;
}

.form-group label {
    margin-bottom: 6px;
    display: inline-block;
    color: #FEC012;
    font-family: "Croissant One", serif;
    font-size: 15px;
}

.form-group select {
    appearance: menulist;
}

.form-group select {
    color: #696969;
}


input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.form-control {
    height: 48px;
    outline: none;
    background: none;
    border: 1px solid #dddddd21;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    line-height: 1.5;
    color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-footer {
    margin-top: 1rem;
}

.l-t {
    margin-bottom: 30px;
}

.l-t h6 {
    font-size: 16px;
    font-family: "Croissant One", serif;
    color: #FEC012;
    font-weight: 500;
}

.l-t p {
    color: #fff;
    font-size: 15px;
    margin-top: 0.3rem;
}

.l-t p a{
    color: #fff;
    text-decoration: none;
}

















/* Media Query */
@media (max-width:576px) {
    .green-leaf {
        display: none;
    }

    .form-group {
        width: 100%;
    }

    .pizza{
        display: none;
    }
}

@media (max-width:767px) {

    .reservation-section {
        padding: 40px 0;
        padding-bottom: 0;
    }

    .reservation-section-content {
        flex-direction: column;
        margin-top: 30px;
    }

    .reservation-form {
        width: 100%;
        padding: 20px;
    }

    .reservation-location {
        width: 100%;
        padding: 20px;
        margin-top: 20px;
    }
}


@media (min-width:577px) and (max-width:767px) {
    .green-leaf img {
        width: 120px;
    }

    .green-leaf {
        top: -40px;
    }
}

@media (min-width:768px) and (max-width:1199px) {
    .reservation-section {
        padding: 60px 0;
    }
}

@media (max-width:1199px) {
    .pizza img {
        width: 100px;
    }

    .ice-cream {
        display: none;
    }
}

@media (min-width: 1200px) and (max-width:1400px) {
    .pizza img {
        width: 120px;
    }

    .ice-cream img {
        width: 200px;
    }
}