.footer-container {
    display: flex;
    padding-bottom: 50px;
    justify-content: space-between;
    padding-right: 80px;
    flex-wrap: wrap;
}

.f-1-desc {
    max-width: 369px;
    color: #fff;
    line-height: 24px;
    margin-top: 1.5rem;
}

.footer-container li {
    list-style: none;
    line-height: 32px;
    color: #fff;
    cursor: pointer;

}

.footer-container a {
    color: #fff;
    text-decoration: none;
}

.footer-container a:hover{
    color: #FEC012;
}

.footer-container ul {
    margin-top: 1.5rem;
}

.f-title {
    color: #FEC012;
    font-family: "Croissant One", serif;
    font-weight: 500;
}

.f-3 ul li {
    display: flex;
    align-items: baseline;
    gap: 10px;
}

.f-3 ul svg {
    color: #FEC012;
}

.address {
    max-width: 301px;
}

.footer-end {
    border-top: 1px solid #ffffff34;
}

.footer-end-container {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
}

.footer-end-icons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.footer-end-desc {
    color: #fff;
}

.footer-end-desc span a {
    color: #FEC012;
    text-decoration: none;
}

.footer-end-desc span a:hover {
    text-decoration: underline;
}

.footer {
    position: relative;
}

.f-leaf {
    position: absolute;
    top: -80px;
    right: 0;
}








@media (max-width:576px) {
    .footer-container {
        gap: 30px;
    }

}


@media (max-width:767px) {
    .footer-container {
        padding-right: 0;
        padding-bottom: 20px;
    }

    .footer-container ul {
        margin-top: 0.7rem;
    }

    .f-leaf {
        display: none;
    }

    .footer-end-container {
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 10px;
        flex-direction: column;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .f-1 {
        width: 50%;
    }

    .f-2 {
        width: 50%;
        padding-left: 60px;
    }

    .f-3 {
        width: 50%;
        margin-top: 30px;
    }
}

@media (min-width:768px) and (max-width:1199px) {
    .footer-container {
        padding-right: 0;
        padding-bottom: 20px;
        gap: 30px;
        justify-content: normal;
    }

    .f-1 {
        width: 30%;
    }

    .f-2 {
        width: 27%;
        padding-left: 40px;
    }

    .f-3 {
        width: 33%;
    }

    .f-leaf {
        display: none;
    }
}

@media (min-width: 1200px) and (max-width:1400px) {
    .f-leaf img {
        width: 100px;
    }
}