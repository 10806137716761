.common-heading p {
    font-family: "Croissant One", serif;
    color: #fff;
    font-size: 15px;
}

.common-heading h2 {
    color: #FEC012;
    font-size: 32px;
    font-family: "DM Serif Text", serif;
    font-weight: 500;
}





@media (max-width:576px) {
    .common-heading h2 {
        font-size: 24px;
    }
}

@media (min-width:577px) and (max-width:767px) {
    .common-heading h2 {
        font-size: 26px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .common-heading h2 {
        font-size: 28px;
    }
}