.menu-section-slider-card {
    position: relative;
    padding: 10px;
    background: url("../../assets/images/home/c-card-b.png");
    background-repeat: no-repeat;
    background-position: top right;
    margin-right: 20px;
}

.menu-slider-card-content {
    background-color: #232126;
    box-shadow: 0px 3px 7px 10px rgba(0, 0, 0, 0.13);
}

.menu-section-slider-card-title {
    color: #FEC012;
    font-family: "DM Serif Text", serif;
    font-weight: 500;
    font-size: 24px;
}

.menu-section-slider-card-desc {
    color: #fff;
    line-height: 24px;
    max-width: 362px;
    margin: 1rem 0;
    min-height: 70px;
    text-align: justify;
}

.slider-card-content {
    padding: 20px;
}


.menu-section-slider-card-img img {
    width: 100%;
}









@media (max-width:576px) {
    .menu-section-slider-card-title {
        font-size: 20px;
    }
}

@media (min-width:577px) and (max-width:767px) {
    .menu-section-slider-card-title {
        font-size: 22px;
    }
}

@media (min-width:769px) and (max-width:900px) {
    .menu-section-slider-card-desc {
        min-height: 140px;
    }
}

@media (min-width:901px) and (max-width:1024px) {
    .menu-section-slider-card-desc {
        min-height: 95px;
    }
}