.about-page-section {
    position: relative;
}

.about-leaf {
    position: absolute;
    top: -130px;
    right: 0;
    z-index: -1;
}

.about-page-section-container {
    margin-top: 80px;
    margin-left: 80px;
    margin-right: 80px;
    background-color: #232126;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.about-page-section-content-heading {
    text-align: center;
}

.about-page-section-content {
    padding: 60px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-page-section-content-desc {
    color: #fff;
    line-height: 24px;
    text-align: center;
    margin: 1.5rem 0;
}

.about-page-section-images {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-end;
}


.about-page-section-img {
    width: 24%;
}

.about-page-section-img img {
    width: 100%;
}



















/* Media Query */
@media (max-width:767px) {
    .about-leaf {
        display: none;
    }

    .about-page-section-container {
        margin-top: 40px;
        margin-left: 0;
        margin-right: 0;
    }

    .about-page-section-content {
        padding: 30px 20px;
    }
}

@media (min-width:768px) and (max-width:1199px) {
    .about-leaf {
        display: none;
    }

    .about-page-section-container {
        margin-top: 60px;
        margin-left: 60px;
        margin-right: 60px;
    }

    .about-page-section-content {
        padding: 40px;
    }
}