.testimonials {
    padding: 80px 0;
    background: url("../../assets/images/common/testimonials-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
}


.testimonials-header {
    text-align: center;
}

.testimonials-slider {
    margin-top: 40px;
    position: relative;
}

.testimonials-slider::before {
    content: "";
    top: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/common/q.png");
    background-repeat: no-repeat;
    background-position: right;
    z-index: -1;
}

.testimonials-desc {
    font-family: "DM Serif Text", serif;
    color: #fff;
    font-size: 32px;
    padding-right: 20px;
    text-align: justify;
}

.client {
    margin-top: 2rem;
    display: flex;
    align-items: center;
}

.client-name {
    font-family: "DM Serif Text", serif;
    color: #fff;
    font-size: 24px;
}

.rating {
    color: #FEC012;
    margin-top: 0.5rem;
}












@media (max-width:576px) {
    .testimonials-desc {
        font-size: 19px;
    }

    .client-img img {
        width: 100px;
    }

    .client-name {
        font-size: 16px;
    }

}

@media (max-width:767px) {
    .testimonials {
        padding: 40px 0;
    }

    .testimonials-slider::before {
        background-position: center;
    }
}

@media (min-width:577px) and (max-width:767px) {
    .testimonials-desc {
        font-size: 21px;
    }

    .client-img img {
        width: 130px;
    }

    .client-name {
        font-size: 18px;
    }

}

@media (min-width:768px) and (max-width:1199px) {
    .testimonials {
        padding: 60px 0;
    }

    .testimonials-desc {
        font-size: 23px;
    }

    .client-img img {
        width: 150px;
    }

    .client-name {
        font-size: 20px;
    }
}

@media (min-width: 1200px) and (max-width:1400px) {
    .testimonials-desc {
        font-size: 25px;
    }

    .client-name {
        font-size: 23px;
    }
}