.common-banner-catering {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(20, 18, 23, 1) 100%), url("../../assets/images/catering/catering-banner.webp");
}

.common-banner-catering .common-banner-content {
    align-items: center;
}

.common-banner-catering .common-banner-description {
    max-width: 970px;
    margin: 1rem 0;
}

.catering-service-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.catering-service-card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    width: 100%;
}

.catering-service-image {
    width: 100%;
    height: auto;
}


.catering-service-card.reverse {
    flex-direction: row-reverse;
}

.catering-service-title {
    font-size: 24px;
    color: #fff;
    margin-bottom: 10px;
    font-family: "Croissant One", serif;
    font-weight: 500;
}

.catering-service-description {
    margin-bottom: 20px;
    color: #fff;
    line-height: 26px;
}

.catering-service-content.text-right {
    text-align: right;
}

.catering-service-content.text-left {
    text-align: left;
}

.catering-service-content .c-btn:hover{
    color: #fff !important;
}


.catering-showcase {
    background-color: #232126;
    color: #fff;
    padding: 40px 20px;
    text-align: center;
}

.showcase-header {
    margin-bottom: 20px;
}

.showcase-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video-container {
    position: relative;
    width: 100%;
    max-width: 700px;
    margin-bottom: 20px;
}

.catering-image {
    width: 100%;
    border-radius: 10px;
}

.video-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
}

.play-icon {
    font-size: 24px;
    color: #ffd700;
}

.showcase-description {
    max-width: 700px;
    padding: 0 20px;
}

.highlight-text {
    font-size: 18px;
    color: #ffd700;
    margin-bottom: 10px;
    font-weight: bold;
}

.description-text {
    font-size: 16px;
    color: #d1d1d1;
}



.catering-menu-section {
    color: #fff;
    position: relative;
    padding-bottom: 100px;
}

.catering-menu-section::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/catering/Frame\ 56\ \(1\)-min.webp");
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: -1;
}

.catering-menu-title {
    text-align: center;
    font-size: 36px;
    margin-bottom: 20px;
}

.catering-menu-content {
    display: flex;
    align-items: flex-start;
    background-color: #232126;
    gap: 20px;
    position: relative;
    z-index: 1;
}

.catering-menu-content::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/catering/Frame\ 40-min.webp");
    background-repeat: no-repeat;
    background-position: bottom right;
    z-index: -1;
}


.catering-menu-categories {
    max-width: 150px;
    background-color: #161518;
}

.catering-category-item {
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s;
}

.catering-category-btn {
    background-color: #b71c1c;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.catering-category-item.active {
    background-color: #232126;
    ;
}

.catering-category-item:hover {
    background-color: #232126;
    ;
}

.catering-category-icon {
    margin: 0 auto;
    width: 40px;
    height: 40px;
}

.catering-category-name {
    display: block;
    margin-top: 10px;
    font-size: 14px;
}

.catering-menu-items {
    flex-grow: 1;
    padding: 20px 40px
}


.catering-category-title {
    font-size: 24px;
    color: #ffd700;
    margin-bottom: 20px;
    font-family: "Croissant One", serif;
    font-weight: 500;
}

.catering-menu-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ffffff47;
    padding-bottom: 10px;
}

.catering-item-title {
    font-size: 18px;
    font-weight: bold;
}

.catering-item-description {
    font-size: 16px;
    color: #d1d1d1;
}

.category-items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 25px;
}


.catering-gallery-section {
    text-align: center;
}

.catering-gallery {
    margin-top: 40px;
}

.catering-gallery img {
    max-width: 100%;
}



.event-banner {
    position: relative;
    background-image: url('../../assets/images/catering/103016\ 1-min.webp');
    /* Replace with your image path */
    background-size: cover;
    background-position: center;
    text-align: left;
    padding: 20px;
    color: #fff;
}

.event-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/catering/Rectangle\ 75-min.webp");
    background-repeat: no-repeat;
    background-position: left;
    z-index: 1;
}

.event-banner-content {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 20px;
}

.event-banner-subtitle {
    font-size: 1.5rem;
    margin: 0;
    font-family: "DM Serif Text", serif;
    font-weight: 500;
}

.event-banner-title {
    font-size: 3rem;
    margin: 10px 0;
    font-family: "DM Serif Text", serif;
    font-weight: 500;
    max-width: 500px;
}

.event-banner-btn {
    background-color: #FEC012;
    color: #fff;
}

.event-banner-btn:hover {
    background: none;
    color: #FEC012;
}

.event-banner-btn.c-btn::before {
    display: none;
}



/* GallerySection.css */
.catering-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    font-family: "DM Serif Text", serif;
    height: 999px;
    background-color: #fff;
    overflow: hidden;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  
  .gallery-item {
    position: relative;
    overflow: hidden;
  }
  
  .gallery-item img {
    width: 100%;
    object-fit: cover;
    margin: -5px;
  }
  
  .overlay {
    background: #FEC012;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    font-size: 0.8rem;
    font-weight: bold;
  }
  
  .overlay h2 {
    margin: 0;
    color: #000; 
  }

  .gallery-item-space{
    padding: 1px;
    overflow: hidden;
  }


  @media (min-width:1401px) and (max-width:1630px){
    .catering-gallery{
        height: 868px;
    }
  }

  @media (min-width:1201px) and (max-width:1400px){
    .catering-gallery{
        height: 741px;
    }
  }

  @media (min-width:1100px) and (max-width:1200px){
    .catering-gallery{
        height: 679px;
    }
  }

  @media (min-width:992px) and (max-width:1099px){
    .catering-gallery{
        height: 610px;
    }
  }


  @media (min-width:769px) and (max-width:991px){
    .catering-gallery{
        height: 470px;
    }
  }

  
  @media (max-width: 480px) {
    .overlay {
      font-size: 1.25rem;
      padding: 8px 0;
    }
  }



@media (max-width: 768px) {
    .catering-gallery  {
        grid-template-columns:1fr;
        height: unset;
      }
    .event-banner {
        height: auto;
        padding: 20px;
    }

    .event-banner-title {
        font-size: 2rem;
    }

    .event-banner-subtitle {
        font-size: 1.25rem;
    }
}

@media (max-width: 480px) {
    .event-banner-content {
        padding: 10px;
    }

    .event-banner-title {
        font-size: 1.5rem;
    }

    .event-banner-subtitle {
        font-size: 1rem;
    }
}




/* Responsive Design */
@media (min-width: 768px) {
    .catering-menu-item {
        width: 46%;
    }
    .catering-showcase {
        padding: 60px 40px;
    }

    .video-container {
        max-width: 800px;
    }


    .showcase-description {
        max-width: 800px;
    }

    .highlight-text {
        font-size: 20px;
    }

    .description-text {
        font-size: 18px;
    }
}



/* Responsive Design */
@media (min-width: 992px) {
    .catering-service-section {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .catering-service-card {
        flex-direction: row;
        margin-bottom: 40px;
    }

    .catering-service-image {
        object-fit: cover;
    }

    .catering-service-content {
        max-width: 60%;
        margin: auto 0;
    }

    .catering-service-title {
        font-size: 34px;
    }

    .catering-service-content.text-right {
        padding-left: 60px;
    }

    .catering-service-content.text-left {
        padding-right: 60px;
    }
}

@media (max-width:991px) {
    .catering-service-card.reverse {
        flex-direction: column;
    }
}


@media (min-width: 577px) and (max-width: 767px) {
    .common-banner-catering {
        height: 440px;
    }
    .video-container video{
        width: 100%;
    }
}

@media (max-width:576px){
    .common-banner-catering {
        height: 550px;
    }
    .video-container video{
        width: 100%;
    }
}