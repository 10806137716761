.container {
    max-width: 1240px;
    margin: 0 auto;
}

.c-p {
    padding: 0 20px;
}

.c-s {
    margin-top: 80px;
}







@media (max-width:767px) {
    .c-s {
        margin-top: 40px;
    }
}

@media (min-width:768px) and (max-width:1199px) {
    .c-s {
        margin-top: 60px;
    }
}