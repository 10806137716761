.common-section {
    padding-top: 60px;
    padding-bottom: 60px;
    background: url("../../assets/images/common/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    backdrop-filter: blur(60px);
}


.common-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.753);
    z-index: -1;
}


.common-section-container {
    display: flex;
    justify-content: space-between;
}

.common-section-content {
    width: 60%;
}

.common-section-desc p {
    max-width: 690px;
    color: #fff;
    line-height: 24px;
    margin-top: 1.5rem;
    text-align: justify;
}

.common-section-title {
    max-width: 400px;
}

.common-section-img {
    width: 36%;
    position: relative;
}

.dots {
    position: absolute;
    bottom: -10px;
    left: -50px;
    z-index: -1;
}







@media (max-width:767px) {
    .common-section {
        margin-top: 0;
        padding: 40px 0;
    }

    .common-section-container {
        flex-direction: column-reverse;
    }

    .common-section-content {
        width: 100%;
        margin-top: 30px;
    }

    .common-section-img {
        width: 100%;
    }

    .common-section-img img {
        width: 100%;
        height: 450px;
        object-fit: contain;
    }

    .dots {
        display: none;
    }
}

@media (min-width:768px) and (max-width:1199px) {
    .common-section {
        margin-top: 0;
        padding: 60px 0;
    }

    .common-section-img img {
        width: 100%;
        position: sticky;
        top: 105px;
    }

    .dots {
        display: none;
    }
}