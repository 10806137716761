.c-btn {
    outline: none;
    background: none;
    border: 1px solid #FEC012;
    color: #FEC012;
    padding: 12px 24px;
    font-family: "Croissant One", serif;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease;
    z-index: 1;
}

.c-btn::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: #FEC012;
    transition: transform 0.5s ease;
    border-radius: 50%;
    z-index: 0;
    transform: translate(-50%, -50%) scale(0);
}

.c-btn:hover::before {
    transform: translate(-50%, -50%) scale(1);
    z-index:-1
}

.c-btn:hover {
    color: #fff;
}

.c-btn:hover .a-arrow {
    transform: translateX(3px) rotate(45deg);
    transition: transform 0.5s ease;
}

.a-arrow {
    font-size: 17px;
    z-index: 2;
}

.btn-text {
    z-index: 2;
}