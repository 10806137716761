@import url('https://fonts.googleapis.com/css2?family=Croissant+One&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=DM+Serif+Display:ital@0;1&family=DM+Serif+Text:ital@0;1&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  background-color: #141217;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}