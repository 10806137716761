/* About Section */
.about-section {
    position: relative;
}

.about-section-card {
    margin: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #141217;
}


.about-section-card::before {
    content: "";
    position: absolute;
    bottom: -120px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: url("../../assets//images/home/—Pngtree—white\ powder\ splashing\ in\ the_5437716\ 1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.about-section-card-img {
    width: 50%;
}

.about-section-card-img img {
    width: 100%;
    height: 100%;
}

.about-section-card-content {
    width: 50%;
    padding: 50px 40px;
    box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.247);
}

.about-section-card-desc {
    color: #ffff;
    margin-top: 1rem;
    margin-bottom: 2rem;
    line-height: 24px;
    text-align: justify;
}

.g-leaf {
    position: absolute;
    right: 0;
    bottom: -60px;
    z-index: -1;
}



/* Category Section */
.category-section-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    padding-top: 80px;
}




/* Menu Section */
.menu-section {
    position: relative;
    background: url("../../assets/images/home/menu-section-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 0;
}

.menu-section-heading {
    text-align: center;
}

.menu-section-slider {
    margin-top: 60px;
}

.blueberry {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.tomato {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}




/* Media Query */
@media (max-width:576px) {
    .about-section-card::before {
        display: none;
    }

    /* Category section */
    .category-section-cards {
        padding-top: 0;
        gap: 20px;
    }


    /* Menu Section */
    .blueberry {
        display: none;
    }

    .tomato {
        display: none;
    }

}

@media (max-width:767px) {
    .about-section-card {
        margin: 40px 0;
        flex-direction: column;
    }

    .about-section-card-img {
        width: 100%;
    }

    .about-section-card-content {
        width: 100%;
        padding: 40px 30px;
    }

    .g-leaf {
        display: none;
    }

    .menu-section {
        padding: 40px 0;
    }

    .menu-section-slider {
        margin-top: 30px;
    }

}

@media (min-width:577px) and (max-width:767px) {
    .about-section-card::before {
        background-position: bottom;
        bottom: -140px;
        width: 94%;
        overflow: hidden;
    }

    .category-section-cards {
        gap: 20px;
        padding-top: 40px;
    }


    /* Menu Section */
    .blueberry img {
        width: 120px;
    }

    .tomato {
        display: none;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .about-section-card {
        margin: 60px 0;
    }

    .about-section-card-img img {
        object-fit: cover;
    }


    .category-section-cards {
        padding-top: 40px;
        gap: 20px;
    }

    .tomato img {
        width: 100px;
    }
}

@media (min-width:991px) and (max-width:1199px) {
    .about-section-card {
        margin: 60px 0;
    }

    .category-section-cards {
        padding-top: 40px;
        gap: 25px;
    }
}

@media (min-width: 991px) and (max-width:1400px) {
    .tomato img {
        width: 120px;
    }
}