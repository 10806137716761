.contact-section {
    background: url("../../assets/images/contact/Black-bg\ 3\ \(1\).png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
}

.contact-section-container {
    background-color: #232126;
}

.contact-section-heading {
    text-align: center;
}

.contact-form-group {
    width: 100%;
}

.textarea-form-control {
    height: 140px;
}

.contact-page-form-footer {
    display: flex;
    justify-content: flex-end;
}

.contact-page-form {
    width: 48%;
    padding: 40px;
}

.contact-page-form-container {
    position: relative;
    z-index: 1;
}

.contact-page-location {
    width: 48%;
}

.location-details {
    padding: 40px;
}

.contact-f {
    padding-top: 20px;
    padding-left: 20px;
}

.error-message {
    color: red !important;
    font-size: 12px !important;
}

.success-message {
    color: #FEC012;
}



















@media (max-width:767px) {
    .contact-section {
        padding: 40px 0;
    }

    .contact-page-form {
        width: 100%;
        padding: 25px;
    }

    .contact-page-location {
        width: 100%;
    }

    .location-details {
        padding: 25px;
    }
}

@media (min-width:768px) and (max-width:1199px) {
    .contact-section {
        padding: 60px 0;
    }
}