/* NotFound.css */
.not-found-wrapper {
    background-image: url('../../assets/images/common/bg.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.not-content-box {
    background: rgba(0, 0, 0, 0.7);
    padding: 40px;
    border-radius: 10px;
}

.not-found-title {
    font-size: 6rem;
    color: #FFBB00;
    font-family: 'Playfair Display', serif;
}

.not-subtitle {
    font-size: 2.5rem;
    color: #ffffff;
    margin-top: 20px;
}

.not-description {
    font-size: 1.5rem;
    color: #cccccc;
    margin: 20px 0;
}

.not-menu-btn,
.not-home-btn {
    display: inline-block;
    margin: 15px;
    padding: 12px 30px;
    border-radius: 5px;
    background-color: #FFBB00;
    color: #000;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}

.not-menu-btn:hover,
.not-home-btn:hover {
    background-color: #fff;
    color: #000;
}

.not-menu-btn {
    margin-right: 20px;
}

.not-home-btn {
    background-color: transparent;
    border: 2px solid #FFBB00;
    color: #fff;
}

.not-home-btn:hover {
    background-color: #FFBB00;
    color: #000;
}

@keyframes fall-down {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.not-found-title {
    animation: fall-down 1s ease-out;
}