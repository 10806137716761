.common-banner {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(20, 18, 23, 1) 100%), url("../../assets/images/common/homemade-food\ 1.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 550px;
    background-size: cover;
}

.common-banner-content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    color: #fff;
    padding: 80px 0;
}

.common-banner-desc {
    display: flex;
    align-items: center;
    justify-content: center
}

.common-banner-desc a{
    text-decoration: none;
    cursor: pointer;
    color: #fff;
}

.common-banner-text {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.common-banner-title {
    font-size: 48px;
    font-family: "DM Serif Text", serif;
    font-weight: 500;
}

.desc-y-c {
    color: #FEC012;
}











@media (max-width:576px) {
    .common-banner {
        height: 250px;
    }

    .common-banner-content {
        padding: 40px 0;
    }

    .common-banner-title {
        font-size: 28px;
    }
}

@media (min-width:577px) and (max-width:767px) {
    .common-banner {
        height: 300px;
    }

    .common-banner-content {
        padding: 40px 0;
    }

    .common-banner-title {
        font-size: 32px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .common-banner {
        height: 350px;
    }

    .common-banner-content {
        padding: 60px 0;
    }

    .common-banner-title {
        font-size: 36px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .common-banner {
        height: 400px;
    }

    .common-banner-content {
        padding: 60px 0;
    }

    .common-banner-title {
        font-size: 40px;
    }
}

@media (min-width:1200px) and (max-width:1400px) {
    .common-banner {
        height: 450px;
    }

    .common-banner-content {
        padding: 60px 0;
    }

    .common-banner-title {
        font-size: 42px;
    }
}