.home-banner {
    position: relative;
}

.home-banner-title {
    font-size: 64px;
    line-height: 73px;
    color: #fff;
    font-family: "DM Serif Text", serif;
    max-width: 588px;
    font-weight: 500;
}

.home-banner-title span {
    color: #FEC012;
}

.home-banner-desc {
    color: #fff;
    font-size: 20px;
    line-height: 31px;
    max-width: 533px;
    margin: 1.5rem 0;
}

.home-banner-slider-card {
    position: relative;
    height: 890px;
    overflow: hidden;
}


.c-1 {
    background: url("../../assets/images/home/home-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.c-2 {
    background: url("../../assets/images/home/Banner\ 2.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}


.home-banner-content-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.home-banner-content {
    position: relative;
}

.animate .home-banner-content {
    animation: fadeUp 0.3s ease-in;
}


.rope {
    position: absolute;
    bottom: 0;
    right: 0;
}

.leaf {
    position: absolute;
    bottom: -70px;
}



@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.round-arrow {
    position: absolute;
    bottom: 10px;
    left: 50%;
    height: 42px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FEC012;
    color: #FEC012;
    border-radius: 35px;
    animation: floatAnimation 3s ease-in-out infinite;
    cursor: pointer;
}


@keyframes floatAnimation {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}












/* Media Query */
@media (max-width: 576px) {
    .c-1 {
        background-position: 70%;
    }

    .c-2 {
        background-position: 70%;
    }

}

@media (min-width:577px) and (max-width:991px) {
    .c-1 {
        background-position: center;
    }

    .c-2 {
        background-position: center;
    }

}

@media (max-width: 576px) {
    .home-banner-slider-card {
        height: 550px;
    }

    .home-banner-title {
        font-size: 32px;
        line-height: 40px;
    }

    .home-banner-desc {
        margin: 1rem 0;
    }

    .home-banner-content {
        margin-top: 40px;
    }

    .rope {
        display: none;
    }

    .leaf {
        display: none;
    }
}


@media (min-width: 577px) and (max-width: 767px) {
    .leaf {
        bottom: -50px;
    }

    .rope {
        right: -40px;
    }

    .rope img {
        width: 70%;
    }

    .leaf img {
        width: 190px;
    }

    .home-banner-slider-card {
        height: 650px;
    }

    .home-banner-title {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .leaf img {
        width: 220px;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
    .home-banner-title {
        font-size: 50px;
        line-height: 56px;
    }

    .home-banner-slider-card {
        height: 700px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .home-banner-title {
        font-size: 54px;
        line-height: 58px;
    }

    .home-banner-slider-card {
        height: 800px;
    }
}

@media (min-width: 1200px) and (max-width:1400px) {
    .home-banner-title {
        font-size: 58px;
        line-height: 60px;
    }

    .home-banner-slider-card {
        height: 850px;
    }
}