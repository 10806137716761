.story-section {
    background: url("../../assets/images/services/Black-bg\ 2.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.story-section-container {
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
}

/* .story-section-container::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: brightness(15%);
    background: url("../../assets/images/services/—Pngtree—fast\ food\ seamless\ background\ with_5082565\ \(2\)\ 1.png");
    background-position: center;
    background-size: cover;
} */

.story-section-content {
    max-width: 737px;
    margin: 0 auto;
}

.story-section-heading {
    text-align: center;
}

.story-section-top-desc {
    color: #fff;
    line-height: 24px;
    margin-top: 1.5rem;
    text-align: justify;
}


.story-section-images {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.story-section-image-c {
    background: url("../../assets/images/services/Rectangle\ 45.png");
    background-repeat: no-repeat;
    background-position: top left;
}

.story-section-image-d {
    background: url("../../assets/images/services/Rectangle\ 46.png");
    background-repeat: no-repeat;
    background-position: top right;
}

.story-section-image {
    width: 48%;
    padding: 15px;
}

.story-section-bottom-desc {
    color: #fff;
    line-height: 24px;
    margin-top: 1rem;
    text-align: justify;
}









@media (max-width:767px) {
    .story-section-container {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .story-section-images {
        flex-direction: column;
    }

    .story-section-image {
        width: 100%;
        padding: 0;
    }

    .story-section-image img {
        width: 100%;
    }

    .story-section-image-c {
        background: none;
    }

    .story-section-image-d {
        background: none;
        margin-top: 20px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .story-section-image {
        padding: 10px;
    }
}