.header.sticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
}

.header.sticky .navbar {
    background-color: #232126;
    padding: 10px 20px;
    margin-top: 20px;
    animation: slideDown 0.3s ease backwards;
}

.header-container {
    position: relative;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    animation: slideUp 0.3s ease forwards;
}

.nav-items {
    display: flex;
    gap: 20px;
}

.nav-items li {
    list-style: none;
}

.nav-contact-info {
    display: flex;
    gap: 10px;
}

.nav-item {
    color: #fff;
    text-decoration: none;
    font-family: "Croissant One", serif;
}

.nav-item.active {
    color: #FEC012;
}

.header-contact-info p {
    color: #fff;
    font-size: 15px;
}

.m-no {
    text-decoration: none;
    font-family: "Croissant One", serif;
    font-size: 16px;
}

.nav-logo {
    z-index: 10;
}

.nav-toggle {
    display: none;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;
    color: #fff;
    font-size: 24px;
}

.nav-toggle:focus {
    outline: none;
}

.nav-toggle span {
    width: 20px;
    height: 3px;
    background-color: #fff;
    margin-bottom: 5px;
    transition: transform 0.3s, opacity 0.3s;
}

.nav-toggle.active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.nav-toggle.active span:nth-child(2) {
    opacity: 0;
}

.nav-toggle.active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}


@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slideUp {
    from {
        transform: translateY(-20%);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes slideIn {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
}


/* Media Query */
@media screen and (max-width: 991px) {
    .nav-items {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        background-color: #232126;
        box-sizing: border-box;
        z-index: 9;
        padding-top: 90px;
        gap: 0;
        animation: slideDown 0.3s ease forwards;
    }

    .nav-items.active {
        display: flex;
    }

    .nav-items.hide {
        animation: slideIn 0.3s ease backwards;
    }

    .nav-items li {
        padding: 20px;
    }

    .nav-contact-info {
        display: none;
    }

    .nav-toggle {
        display: flex;
    }
}

@media screen and (max-width:576px) {
    .nav-logo img {
        width: 120px;
    }
}

@media (max-width:1300px) {
    .header.sticky .navbar {
        margin-top: 0;
    }
}