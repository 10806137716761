.category-card {
    position: relative;
    background: url("../../assets/images/home/c-card-b.png");
    background-repeat: no-repeat;
    background-position: top right;
}

.category-card-content {
    padding-top: 10px;
    padding-right: 10px;
}

.category-card-title {
    color: #FEC012;
    font-family: "DM Serif Text", serif;
    font-weight: 500;
    font-size: 24px;
}

.category-card-desc {
    color: #fff;
    line-height: 24px;
    max-width: 362px;
    margin-top: 0.8rem;
    min-height: 70px;
}






@media (max-width:576px) {
    .category-card {
        width: 100%;
    }

    .category-card-img img {
        width: 100%;
    }

    .category-card-title {
        font-size: 20px;
    }
}

@media (min-width:577px) and (max-width:991px) {
    .category-card {
        width: 48%;
    }

    .category-card-img img {
        width: 100%;
    }

    .category-card-title {
        font-size: 22px;
    }
}

@media (min-width:992px) and (max-width:1400px) {
    .category-card {
        width: 31%;
    }

    .category-card-img img {
        width: 100%;
    }
}