.menu-page-section {
    position: relative;
    background: url("../../assets/images/menu/menu-page-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
}


.spoon {
    position: absolute;
    left: 50px;
    top: 180px;
    z-index: -1;
}


.avacado {
    position: absolute;
    left: 700px;
    top: 1300px;
    z-index: -1;
}

.burger {
    position: absolute;
    left: 520px;
    top: 700px;
    z-index: -1;
}

.menu-content-section {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.menu-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.item-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-detail {
    width: 58%;
}

.menu-image {
    width: 38%;
}

.image-container {
    padding: 30px;
}

.menu-item {
    margin-top: 2rem;
}

.menu-title {
    color: #FEC012;
    font-family: "DM Serif Text", serif;
    font-size: 48px;
    font-weight: 500;
}

.item-name {
    color: #fff;
}

.item-detail {
    font-size: 24px;
}

.item-price {
    color: #FEC012;
}

.menu-item-desc {
    color: #ffffff57;
}

.first {
    background: url("../../assets/images/menu/Vector\ \(3\).png");
    background-repeat: no-repeat;
    background-size: contain;
}

.first img {
    margin: 30px;
}

.second {
    background: url("../../assets/images/menu/Vector\ \(2\).png");
    background-repeat: no-repeat;
    background-size: contain;
}

.second img {
    margin: 15px;
}

.third {
    background: url("../../assets/images/menu/Vector\ \(1\).png");
    background-repeat: no-repeat;
    background-size: contain;
}

.third img {
    margin: 20px;
}

.fourth {
    display: flex;
    justify-content: flex-end;
}





.tab-list {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
}

.tab-item {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: lightgray;
    margin-right: 5px;
}

.veg.active {
    background-color: green;
    color: white;
}

.nonVeg.active {
    background-color: red;
    color: white;
}








@media (max-width:576px) {
    .menu-title {
        font-size: 24px;
    }

    .item-detail {
        font-size: 17px;
    }
}


@media (max-width:991px) {
    .menu-page-section {
        padding: 40px 0;
    }

    .spoon {
        left: 0;
    }

    .spoon img {
        width: 150px;
    }

    .menu-detail {
        width: 100%;
    }

    .menu-image {
        display: none;
    }

    .burger {
        left: 160px;
    }

    .burger img {
        width: 150px;
    }

    .avacado {
        left: 50px;
    }

    .avacado img {
        width: 150px;
    }

    .menu-content-section {
        gap: 30px;
    }

}

@media (min-width:577px) and (max-width:767px) {
    .menu-title {
        font-size: 26px;
    }

    .item-detail {
        font-size: 18px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .menu-title {
        font-size: 28px;
    }

    .item-detail {
        font-size: 19px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .menu-page-section {
        padding: 60px 0;
    }

    .menu-content-section {
        gap: 40px;
    }

    .menu-title {
        font-size: 34px;
    }

    .item-detail {
        font-size: 20px;
    }

    .image-container {
        padding: 0;
    }

    .burger {
        left: 160px;
    }

    .burger img {
        width: 200px;
    }

    .avacado {
        left: 50px;
    }

    .avacado img {
        width: 200px;
    }

    .spoon {
        left: 0;
    }

    .spoon img {
        width: 200px;
    }

    .first img {
        width: 300px;
    }

    .second img {
        width: 250px;
    }

    .third img {
        width: 300px;
    }

    .fourth img {
        width: 100%;
    }
}

@media (min-width:1200px) and (max-width:1400px) {
    .menu-title {
        font-size: 40px;
    }

    .item-detail {
        font-size: 22px;
    }

    .image-container {
        padding: 0;
    }

    .burger {
        left: 160px;
    }

    .burger img {
        width: 200px;
    }

    .avacado {
        left: 50px;
    }

    .avacado img {
        width: 200px;
    }

    .spoon {
        left: 0;
    }

    .spoon img {
        width: 200px;
    }
}